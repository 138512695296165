import React, { useState } from "react";
import ProductsService from "services/ProductsService";
import { message } from "antd";
export const ProductsContext = React.createContext({});

export const ProductsProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [dataProduct, setDataProduct] = useState({});

  const loadAllProducts = async () => {
    message.loading("Carregando dados", 1000000);
    let response = await ProductsService.list();
    message.destroy();
    setProducts(response);
  };

  const loadProductById = async (id) => {
    message.loading("Carregando dados", 1000000);
    let response = await ProductsService.findOne(id);
    message.destroy();
    setDataProduct(response);
  };

  return (
    <ProductsContext.Provider
      value={{
        products,
        setProducts,
        dataProduct,
        setDataProduct,
        loadAllProducts,
        loadProductById
      }}
    >
      {props.children}
    </ProductsContext.Provider>
  );
};
