import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import Routes from 'routes'
import { resources } from 'lang';
import useBodyClass from 'utils/hooks/useBodyClass';
import { UsersProvider } from '../providers/users';
import { RankingProvider } from '../providers/ranking';
import { ProductsProvider } from '../providers/products';
import { VideosProvider } from '../providers/videos';
import { ContactProvider } from '../providers/contact';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const Views = () => {
	const { locale, direction } = useSelector(state => state.theme);
	const currentAppLocale = resources[locale];
	useBodyClass(`dir-${direction}`);
	useEffect(() => {
        if (window === undefined) {
            return
        }
        const root = window.document.documentElement
        root.setAttribute('dir', direction)
    }, [direction]);
	return (
		<ConfigProvider direction={direction} locale={currentAppLocale.antd}>
			<UsersProvider>
				<RankingProvider>
					<ProductsProvider>
						<VideosProvider>
							<ContactProvider>
								<GoogleReCaptchaProvider 
									reCaptchaKey="6LeUfeUlAAAAAF8v4SSh45qtytmkL8tpMveDoj_F"
									scriptProps={{
										async: false,
										defer: false,
										appendTo: "head",
										nonce: undefined,
									  }}
								>
									<Routes />	
								</GoogleReCaptchaProvider>
							</ContactProvider>
						</VideosProvider>
					</ProductsProvider>
				</RankingProvider>
			</UsersProvider>
		</ConfigProvider>
	)
}

export default Views;