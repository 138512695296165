import { env } from "../configs/EnvironmentConfig";
import api from "./api";
const BASE_API = env.API_ENDPOINT_URL;

const UsersService = {};

UsersService.list = async () => {
  const req = await api.fetcher(`${BASE_API}/users/all`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

UsersService.listAdmins = async () => {
  const req = await api.fetcher(`${BASE_API}/users/controllers`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

UsersService.listActiveds = async () => {
  const req = await api.fetcher(`${BASE_API}/users/activeds`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

UsersService.findOne = async (id) => {
  const req = await api.fetcher(`${BASE_API}/users/${id}`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

UsersService.add = async (data) => {
  const req = await api.fetcher(`${BASE_API}/users/add`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

UsersService.update = async (id, data) => {
  const req = await api.fetcher(`${BASE_API}/users/update/${id}`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

UsersService.saveList = async (data) => {
  const req = await api.fetcher(`${BASE_API}/users/addList`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

UsersService.delete = async (id) => {
  const req = await api.fetcher(`${BASE_API}/users/inactivate/${id}`, { method: "POST" });
  const response = await api.verifyResponse(req);
  return response.data;
};

export default UsersService;
