import { env } from "../configs/EnvironmentConfig";
//import fetch from 'auth/FetchInterceptor'

const BASE_API = env.API_ENDPOINT_URL;

const AuthService = {}

AuthService.login = async (params) => {	
	const req = await fetch(`${BASE_API}/auth/login`, {
		method: "POST",
		headers: {
		  Accept: "application/json",
		},
		body:JSON.stringify(params)
	  });
	
	  const json = await req.json();
	  return json;
  };

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}

export default AuthService;