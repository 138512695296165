import api from "./api";
import { env } from "../configs/EnvironmentConfig";
const BASE_API = env.API_ENDPOINT_URL;

const VideosService = {};

VideosService.list = async () => {
  const req = await api.fetcher(`${BASE_API}/videos/all`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

VideosService.findOne = async (id) => {
  const req = await api.fetcher(`${BASE_API}/videos/${id}`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

VideosService.add = async (data) => {
  const req = await api.fetcher(`${BASE_API}/videos/add`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

VideosService.update = async (id, data) => {
  const req = await api.fetcher(`${BASE_API}/videos/update/${id}`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

export default VideosService;