import React, { useState } from "react";
import ContactService from "services/ContactService";
import { message } from "antd";
export const ContactContext = React.createContext({});

export const ContactProvider = (props) => {
  const [contacts, setContacts] = useState([]);
  const [dataContact, setDataContact] = useState({});

  const loadAllContacts = async () => {
    message.loading("Carregando dados", 1000000);
    let response = await ContactService.list();
    message.destroy();
    setContacts(response);
  };

  const loadContactById = async (id) => {
    message.loading("Carregando dados", 1000000);
    let response = await ContactService.findOne(id);
    message.destroy();
    setDataContact(response);
  };

  return (
    <ContactContext.Provider
      value={{
        contacts,
        setContacts,
        dataContact,
        setDataContact,
        loadAllContacts,
        loadContactById
      }}
    >
      {props.children}
    </ContactContext.Provider>
  );
};
