import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login-1')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register-1')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'home',
        path: `${APP_PREFIX_PATH}/home`,
        component: React.lazy(() => import('views/app-views/home')),
    },
    {
        key: 'admins',
        path: `${APP_PREFIX_PATH}/admins`,
        component: React.lazy(() => import('views/app-views/admins')),
    },
    {
        key: 'admins.edit',
        path: `${APP_PREFIX_PATH}/admins/edit/:id`,
        component: React.lazy(() => import('views/app-views/admins/edit')),
    },
    {
        key: 'admins.add',
        path: `${APP_PREFIX_PATH}/admins/add`,
        component: React.lazy(() => import('views/app-views/admins/add')),
    },
    {
        key: 'users',
        path: `${APP_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/app-views/users')),
    },
    {
        key: 'users.edit',
        path: `${APP_PREFIX_PATH}/users/edit/:id`,
        component: React.lazy(() => import('views/app-views/users/edit')),
    },
    {
        key: 'users.add',
        path: `${APP_PREFIX_PATH}/users/add`,
        component: React.lazy(() => import('views/app-views/users/add')),
    },
    {
        key: 'users.actives',
        path: `${APP_PREFIX_PATH}/users/actives`,
        component: React.lazy(() => import('views/app-views/users/activeds')),
    },{
        key: 'ranking',
        path: `${APP_PREFIX_PATH}/ranking`,
        component: React.lazy(() => import('views/app-views/ranking')),
    },
    {
        key: 'ranking.edit',
        path: `${APP_PREFIX_PATH}/ranking/edit/:id`,
        component: React.lazy(() => import('views/app-views/ranking/edit')),
    },
    {
        key: 'ranking.add',
        path: `${APP_PREFIX_PATH}/ranking/add`,
        component: React.lazy(() => import('views/app-views/ranking/add')),
    },
    {
        key: 'products',
        path: `${APP_PREFIX_PATH}/products`,
        component: React.lazy(() => import('views/app-views/products')),
    },
    {
        key: 'products.edit',
        path: `${APP_PREFIX_PATH}/products/edit/:id`,
        component: React.lazy(() => import('views/app-views/products/edit')),
    },
    {
        key: 'products.add',
        path: `${APP_PREFIX_PATH}/products/add`,
        component: React.lazy(() => import('views/app-views/products/add')),
    },{
        key: 'videos',
        path: `${APP_PREFIX_PATH}/videos`,
        component: React.lazy(() => import('views/app-views/videos')),
    },
    {
        key: 'videos.edit',
        path: `${APP_PREFIX_PATH}/videos/edit/:id`,
        component: React.lazy(() => import('views/app-views/videos/edit')),
    },
    {
        key: 'videos.add',
        path: `${APP_PREFIX_PATH}/videos/add`,
        component: React.lazy(() => import('views/app-views/videos/add')),
    },{
        key: 'contact',
        path: `${APP_PREFIX_PATH}/contact`,
        component: React.lazy(() => import('views/app-views/contact')),
    },
    {
        key: 'contact.edit',
        path: `${APP_PREFIX_PATH}/contact/edit/:id`,
        component: React.lazy(() => import('views/app-views/contact/edit')),
    },
    {
        key: 'contact.add',
        path: `${APP_PREFIX_PATH}/contact/add`,
        component: React.lazy(() => import('views/app-views/contact/add')),
    }
]