import api from "./api";
import { env } from "../configs/EnvironmentConfig";
const BASE_API = env.API_ENDPOINT_URL;

const ContactService = {};

ContactService.list = async () => {
  const req = await api.fetcher(`${BASE_API}/contact/all`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

ContactService.findOne = async (id) => {
  const req = await api.fetcher(`${BASE_API}/contact/${id}`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

ContactService.add = async (data) => {
  const req = await api.fetcher(`${BASE_API}/contact/add`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

ContactService.update = async (id, data) => {
  const req = await api.fetcher(`${BASE_API}/contact/update/${id}`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

ContactService.listSenders = async () => {
  const req = await api.fetcher(`${BASE_API}/senders/all`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

ContactService.updateSender = async (id, data) => {
  const req = await api.fetcher(`${BASE_API}/senders/update/${id}`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

export default ContactService;