import api from "./api";
import { env } from "../configs/EnvironmentConfig";
const BASE_API = env.API_ENDPOINT_URL;

const ProductsService = {};

ProductsService.list = async () => {
  const req = await api.fetcher(`${BASE_API}/products/all`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

ProductsService.findOne = async (id) => {
  const req = await api.fetcher(`${BASE_API}/products/${id}`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

ProductsService.add = async (data) => {
  const req = await api.fetcher(`${BASE_API}/products/add`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

ProductsService.update = async (id, data) => {
  const req = await api.fetcher(`${BASE_API}/products/update/${id}`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

ProductsService.delete = async (id) => {
  const req = await api.fetcher(`${BASE_API}/products/inactivate/${id}`, { method: "POST" });
  const response = await api.verifyResponse(req);
  return response.data;
};

export default ProductsService;
