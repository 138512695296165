import React, { useState } from "react";
import RankingService from "services/RankingService";
import { message } from "antd";
export const RankingContext = React.createContext({});

export const RankingProvider = (props) => {
  const [ranking, setRanking] = useState([]);
  const [dataRanking, setDataRanking] = useState({});

  const loadAllRanking = async () => {
    message.loading("Carregando dados", 1000000);
    let response = await RankingService.list();
    message.destroy();
    setRanking(response);
  };

  const loadRankingById = async (id) => {
    message.loading("Carregando dados", 1000000);
    let response = await RankingService.findOne(id);
    message.destroy();
    setDataRanking(response);
  };

  return (
    <RankingContext.Provider
      value={{
        ranking,
        setRanking,
        dataRanking,
        setDataRanking,
        loadAllRanking,
        loadRankingById
      }}
    >
      {props.children}
    </RankingContext.Provider>
  );
};
