import React, { useState } from "react";
import VideosService from "services/VideosService";
import { message } from "antd";
export const VideosContext = React.createContext({});

export const VideosProvider = (props) => {
  const [videos, setVideos] = useState([]);
  const [dataVideo, setDataVideo] = useState({});

  const loadAllVideos = async () => {
    message.loading("Carregando dados", 1000000);
    let response = await VideosService.list();
    message.destroy();
    setVideos(response);
  };

  const loadVideoById = async (id) => {
    message.loading("Carregando dados", 1000000);
    let response = await VideosService.findOne(id);
    message.destroy();
    setDataVideo(response);
  };

  return (
    <VideosContext.Provider
      value={{
        videos,
        setVideos,
        dataVideo,
        setDataVideo,
        loadAllVideos,
        loadVideoById
      }}
    >
      {props.children}
    </VideosContext.Provider>
  );
};
