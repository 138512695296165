import api from "./api";
import { env } from "../configs/EnvironmentConfig";
const BASE_API = env.API_ENDPOINT_URL;

const RankingService = {};

RankingService.list = async () => {
  const req = await api.fetcher(`${BASE_API}/ranking/all`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

RankingService.findOne = async (id) => {
  const req = await api.fetcher(`${BASE_API}/ranking/${id}`, { method: "GET" });
  const response = await api.verifyResponse(req);
  return response.data;
};

RankingService.add = async (data) => {
  const req = await api.fetcher(`${BASE_API}/ranking/add`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

RankingService.update = async (id, data) => {
  const req = await api.fetcher(`${BASE_API}/ranking/update/${id}`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

RankingService.saveList = async (data) => {
  const req = await api.fetcher(`${BASE_API}/ranking/addList`, { method: "POST", body:JSON.stringify(data) });
  const response = await api.verifyResponse(req);
  return response.data;
};

export default RankingService;
