import { message } from "antd";
import { AUTH_TOKEN , USER_DATA } from 'constants/AuthConstant';

const updateOptions = (options,token) => {
    const update = { ...options };
    update.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...update.headers,
    };
    if(update.headers["Content-Type"] === null)
    {
        delete update.headers["Content-Type"];
    }

    return update;
}

const logout = ()=>{
    window.location = "/login";
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER_DATA);
}

const api = {
    fetcher:async (url, options) =>
    {
        const token = localStorage.getItem(AUTH_TOKEN);
        return fetch(url, updateOptions(options,token));
    },
    verifyResponse:async(req)=>
    {
        if( req.status === 401 ){
            logout();
            return false;
        }
        
        let data = await req.json();

        if(data.status === "success")
        {
            message.success(data.message);
        } else {
            message.error(data.message);
        }

        return data;
    }
}


export default api;
