import React, { useState } from "react";
import UsersService from "services/UsersService";
import { message } from "antd";
export const UsersContext = React.createContext({});

export const UsersProvider = (props) => {
  const [users, setUsers] = useState([]);
  const [activeds, setActiveds] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [dataUser, setDataUser] = useState({});

  const loadAllUsers = async () => {
    message.loading("Carregando dados", 1000000);
    let response = await UsersService.list();
    message.destroy();
    setUsers(response);
  };

  const loadAllUsersActiveds = async () => {
    message.loading("Carregando dados", 1000000);
    let response = await UsersService.listActiveds();
    message.destroy();
    setActiveds(response);
  };

  const loadAllAdmins = async () => {
    message.loading("Carregando dados", 1000000);
    let response = await UsersService.listAdmins();
    message.destroy();
    setAdmins(response);
  };

  const loadUserById = async (id) => {
    message.loading("Carregando dados", 1000000);
    let response = await UsersService.findOne(id);
    message.destroy();
    setDataUser(response);
  };

  return (
    <UsersContext.Provider
      value={{
        users,
        activeds,
        admins,
        dataUser,
        setAdmins,
        setUsers,
        setActiveds,
        setDataUser,
        loadAllUsers,
        loadAllAdmins,
        loadAllUsersActiveds,
        loadUserById
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
};
